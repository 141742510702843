/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


 window.Vue = require('vue');

 import'./bulma';

 import 'jquery.repeater/jquery.repeater.min.js';

 import'./script';


 // import'./datatables/pdfmake.min.js';
 // import'./datatables/vfs_fonts.js';
 // import'./datatables/datatables.min.js';


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
 






 import Collapse from './vue-bulma-collapse/src/Collapse'
 import CollapseItem from './vue-bulma-collapse/src/Item'



 import Dropzone from './components/Dropzone'
 import Uploader from './components/Uploader'



 import Message from './components/messages'

 import SingleSelect from './components/SingleSelect'

 import MurabbaEditor from './ckeditor/MurabbaEditor';




  export {
  	
 	Collapse,
 	CollapseItem,
 	Message,
 	Dropzone,
 	Uploader,
 	SingleSelect,
 	MurabbaEditor,
 }

const app = new Vue({
	el: '#app',
	components: {
		
		Collapse,
		CollapseItem,
		Message,
		Dropzone,
		Uploader,
		SingleSelect,
		MurabbaEditor,

	}
});
