<template>
    <div>
        <div ref="dropzone" class="dropzone"></div>
    </div>
</template>

<script>
    import Dropzone from 'dropzone';
    import axios from 'axios';

    export default {
        name: 'dropzone',
        dropZone: null,
        props: {
            url: {
                type: String,
                required: true
            },
            deleteUrl: {
                type: String,
                required: true
            },
            id: String
        },
        data() {
            return {
                photos: []
            };
        },
        methods: {
            initDropzone() {
                this.dropzone = new Dropzone(this.$refs.dropzone, {
                    url: this.url,
                    paramName: 'image',
                    dictDefaultMessage:
                        `<div class='dropzone-message-wrapper'>
                        <i class='fa fa-upload dropzone-start-message'></i>
                        <h4>يرجى اختيار الصور</h4>
                    </div>`,
                    dictRemoveFile: 'حذف',
                    addRemoveLinks: true,
                    acceptedFiles: 'image/*',
                    headers: {
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
                    }
                });
                this.dropzone.on('success', this.onSuccess.bind(this));
                this.dropzone.on('removedfile', this.onRemoved.bind(this));
            },
            onSuccess(file, response) {
                this.photos.push({ fileName: file.name, id: response.result.id });
            },
            onRemoved(file) {
                const photo = this.photos.filter((photo) =>
                    photo.fileName === file.name
                )[0];

                this.photos = this.photos.filter((photo) =>
                    photo.fileName !== file.name
                );

                if (! photo) {
                    return;
                }

                axios.delete(`${this.deleteUrl}/${photo.id}`, {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
                });
            }
        },
        mounted() {
            Dropzone.autoDiscover = false;
            this.initDropzone();
        }
    };
</script>

<style>
    .dropzone {
        background: white !important;
        border: 2px dashed #48285e  !important;
        border-radius: 20px !important;
        cursor: pointer !important;
    }

    .dropzone-message-wrapper {
        color: #a5a9b1 !important;
    }
    .dropzone-start-message {
        font-size: 54px !important;
    }
</style>