<template>
  <div>
    <textarea :id="id" :name="name" rows="7" class="form-control cke_rtl" style="display:none;">
      {{ OldData }}
    </textarea>
  </div>
</template>

<script>
import './neo.css';
import './samples.css';
import './ckeditor.js';


export default {
  name: 'MurabbaEditor',

  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    OldData:{
      type: String,
      required: false
    }   
  },
  mounted () {
    CKEDITOR.replace( this.id , 
    {
      language :'ar',
      uiColor : '#f4f6fb',
      filebrowserImageBrowseUrl: '/ar/laravel-filemanager?type=Images',
      filebrowserImageUploadUrl: '/ar/laravel-filemanager/upload?type=Images&_token=',
      filebrowserBrowseUrl: '/ar/laravel-filemanager?type=Files',
      filebrowserUploadUrl: '/ar/laravel-filemanager/upload?type=Files&_token='
      
    }

    )
  }


};
</script>


