<template>
  <article class="message" v-bind:class="type" v-show="isVisible">
    <div class="message-header">
      <p>{{ title }}</p>
      <button class="delete" aria-label="delete" @click="isVisible = false"></button>
    </div>
    <div class="message-body">
      <slot></slot>
    </div>
  </article>
</template>

<script>
  export default {
    name: 'message',
    props:['title'  , 'type'],

    data(){
      return{
        isVisible: true
      };
    },
  }
</script>
<style type="text/css">
.message {
  position: absolute;
  z-index: 99999;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  animation:animatetop 2s;
}
.message .delete::before, .message .delete::after{
      background-color: #fff;
}

@keyframes animatetop{
  from{top:-700px;opacity:0} 
  to{top:0;opacity:1}
}

</style>

